import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Avatar,
  IconButton,
  Typography,
  Card,
} from "@material-tailwind/react";
import nuocep from "../../image/gallery/bun.jpg";
export function DialogWithImage({ image }) {
  const [open, setOpen] = React.useState(false);
  const [isFavorite, setIsFavorite] = React.useState(false);

  const handleOpen = () => setOpen((cur) => !cur);
  const handleIsFavorite = () => setIsFavorite((cur) => !cur);

  return (
    <>
      <Card
        className=" cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
        onClick={handleOpen}
      >
        <img
          alt="nature Sewa Logistics img"
          className="md:h-20 h-44 w-full max-w-full rounded-lg object-cover object-center"
          src={image}
        />
      </Card>
      <Dialog size="xl" open={open} handler={handleOpen}>
        <DialogBody>
          <img
            alt="nature Sewa Logistics img" 
            className="h-[48rem] w-full rounded-lg object-cover object-center"
            src={image}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}
