import React from "react";
import cang from "../../image/background/cang.png";
import cang1 from "../../image/background/cang1.jpg";
export default function AboutUs() {
  return (
    <div className="w-full min-h-screen grid grid-cols-1 md:grid-cols-2 gap-16 md:mt-32 mt-60 px-3">
      <div className="flex justify-end items-center">
        <div className="md:h-[400px] h-[250px] md:max-w-full">
          <img
            className="transition-transform transform hover:scale-110 duration-700 object-cover md:rounded-lg h-full w-full"
            alt="Sewa Logistics img"
            src={cang1}
          />
        </div>
      </div>
      <div className="flex justify-start items-center">
        <div className="text-center">
          <h1
            className="md:text-3xl text-2xl text-red-700"
            style={{
              fontFamily: "Pacifico",
            }}
          >
            SewaLogistics Company
          </h1>
          <p className="font-bold text-4xl md:text-5xl mt-5">ABOUT US</p>
          <h2 className=" text-gray-700 text-base md:text-lg max-w-[650px] leading-relaxed mt-10 px-3 xl:px-0">
            The team of Sewa Logistics has more than 5 years of experience and
            competence Transportation and Customs Clearance in Vietnam. All
            members are equipped with the necessary technical know-how for
            logistics.
          </h2>
          <h2 className=" text-gray-700 text-base md:text-lg max-w-[650px] leading-relaxed mt-5 px-3 xl:px-0">
            We would like to thank all our customers for their support and trust
            over the years and look forward to further development together and
            doing business with you.
          </h2>
        </div>
      </div>
    </div>
  );
}
