import bun from "../image/gallery/bun.jpg";
import chuoi from "../image/gallery/chuoi.png";
import giavi from "../image/gallery/giavi.jpg";

import nuocep from "../image/gallery/nuocep.jpg";
import say from "../image/gallery/say.jpg";
import xoai from "../image/gallery/xoai.jpg";
import than from "../image/gallery/than.jpg";
import tieu from "../image/gallery/tieu.jpg";
import dieu from "../image/gallery/dieu.jpg";

const dataGallery = [
  {
    imageLink: bun,
  },
  {
    imageLink: chuoi,
  },
  {
    imageLink: giavi,
  },
  {
    imageLink: nuocep,
  },
  {
    imageLink: say,
  },
  {
    imageLink: xoai,
  },
  {
    imageLink: than,
  },
  {
    imageLink: tieu,
  },
  {
    imageLink: dieu,
  },
];
export default dataGallery;
