import React, { Suspense, useEffect, useRef, useState } from "react";
import logoTrans from "../image/logo-trans.png";
import logoNoTrans from "../image/logo.png";
import dd from "../image/gallery/chuoi.png";
import Footer from "./components/Footer";
import CarouselHeader from "./components/Carousel";
import IntroComponent from "./components/IntroComponent";
import AboutUs from "./components/AboutUs";
import Products from "./components/Products";
import AgencyTrade from "./components/AgencyTrade";
import Teams from "./components/Teams";
import Contact from "./components/Contact";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

export default function Layout() {
  const [isShow, setIsShow] = useState(false);
  const refTop = useRef(null);
  const refAboutUs = useRef(null);
  const refTeams = useRef(null);
  const refProduct = useRef(null);
  const refAgency = useRef(null);
  const refContact = useRef(null);
  const [divActive, setDivActive] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const divs = [
    { id: 100, name: "About Us", ref: refTop, disabled: true },
    { id: 1, name: "About Us", ref: refAboutUs },
    { id: 2, name: "Services", ref: refProduct },
    { id: 4, name: "Teams", ref: refTeams },
    { id: 3, name: "Agency Trade", ref: refAgency },

    { id: 5, name: "Contact", ref: refContact },
  ];
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = 500;

    setIsScrolled(scrollPosition > scrollThreshold);
    divs.forEach((div) => {
      try {
        const rect = div?.ref?.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

        if (isVisible) {
          setDivActive(div?.id);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  const scrollToDiv = (ref) => {
    try {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth", // Cuộn mượt mà
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div>
        <div className="relative min-h-screen">
          <div
            className={`fixed z-50 w-full h-16 md:h-24 duration-200 ${
              isScrolled
                ? " bg-white shadow-lg border-b-4 border-blue-800 opacity-90"
                : "bg-transparent"
            }`}
          >
            <div className="h-full w-full flex justify-between items-center px-3 py-3 lg:hidden">
              <div className="h-full p-1 border-2 rounded-lg border-white max-h-14 w-fit">
                <img
                  alt="Sewa Logistics img"
                  src={isScrolled ? logoNoTrans : logoTrans}
                  S
                  className=" object-cover h-full"
                />
              </div>

              <div
                onClick={() => setIsShow(!isShow)}
                className={` p-1 border-2 rounded-lg  h-10 w-10 flex justify-center items-center  hover:text-black ${
                  isScrolled ? "border-black" : "border-white text-white"
                }`}
              >
                <FaBars className="text-xl" />
              </div>
            </div>
            <div
              onClick={() => setIsShow(false)}
              className="w-full flex justify-end  h-full lg:hidden"
            >
              <div
                className={` w-3/5  bg-white h-screen transition-transform border-l-4 border-blue-900 duration-700 ${
                  isShow ? "translate-x-0" : " translate-x-full"
                }`}
              >
                <div className="w-full flex justify-between items-center p-3">
                  <p className="text-2xl font-medium">Menu</p>
                  <div
                    onClick={() => setIsShow(false)}
                    className=" p-1  h-10 w-10 flex justify-center items-center text-black"
                  >
                    <IoMdClose className="text-2xl" />
                  </div>
                </div>{" "}
                <div className="p-3">
                  {divs?.map((item) => (
                    <div
                      onClick={() => scrollToDiv(item?.ref)}
                      className={`${
                        item?.disabled && "hidden"
                      } cursor-pointer mt-5 ${
                        divActive === item?.id
                          ? "border-black"
                          : "border-transparent"
                      }  border-b-2 
                     duration-200`}
                    >
                      <p className={`text-xl font-medium m-1 text-black`}>
                        {item?.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="h-full w-full  justify-between items-center px-20 py-3 hidden lg:flex">
              <div className="h-full p-1 border-2 rounded-lg border-white">
                <img
                  alt="Sewa Logistics img"
                  src={isScrolled ? logoNoTrans : logoTrans}
                  className=" object-cover h-full"
                />
              </div>
              <div className="h-full flex items-center gap-6">
                {divs?.map((item) => (
                  <div
                    onClick={() => scrollToDiv(item?.ref)}
                    className={`${item?.disabled && "hidden"} cursor-pointer ${
                      divActive === item?.id
                        ? "border-black"
                        : "border-transparent"
                    }  border-b-2  ${
                      isScrolled ? `hover:border-black  ` : "hover:border-white"
                    } duration-200`}
                  >
                    <p
                      className={`text-xl font-medium font-serif m-1 ${
                        isScrolled ? "text-black " : "text-white"
                      }`}
                    >
                      {item?.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <CarouselHeader
            action1={() => scrollToDiv(refAboutUs)}
            action2={() => scrollToDiv(refContact)}
          />
        </div>{" "}
        <div ref={refTop} />
        <IntroComponent />
        <section ref={refAboutUs}>
          <AboutUs />
        </section>
        <section ref={refProduct}>
          <Products />
        </section>
        <section ref={refTeams}>
          <Teams />
        </section>
        <section ref={refAgency}>
          <AgencyTrade />
        </section>
        <div ref={refContact} />
        <Contact />
        <Footer />
      </div>
    </Suspense>
  );
}
