import React from "react";
import { FaFacebookF, FaPhone } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { SiZalo } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { MdAccessTimeFilled } from "react-icons/md";
import dataGallery from "../../data/dataGallery.js";
import { DialogWithImage } from "./DialogImage.jsx";
const Title = ({ title }) => {
  return <p className="text-white text-xl font-medium uppercase">{title}</p>;
};
export default function Footer() {
  return (
    <div className="w-full">
      <div className="w-full grid grid-cols-1 xl:grid-cols-3 gap-10 px-3 md:px-44 py-16 min-h-64 bg-gray-800">
        <div>
          <Title title={"About Us"} />
          <p className="text-base font-medium text-white mt-2">
            SEA WAVES GLOBAL LOGISTICS COMPANY LIMITED
          </p>
          <div className="flex gap-x-3 text-white opacity-65 items-center mt-5">
            <div className="flex justify-center items-center h-5 w-5">
              <FaLocationDot />
            </div>

            <p className="text-sm">
            195/59 Xo Viet Nghe Tinh, Ward 17, Binh Thanh District,<br/> Ho Chi Minh City, Vietnam
            </p>
          </div>
          <div className="flex gap-x-3 text-white opacity-65 items-center mt-6">
            <div className="flex justify-center items-center h-5 w-5">
              <FaPhone />
            </div>
            <p className="text-sm">+84 929 298 128 (Mr.Henry)</p>
          </div>
          <div className="flex gap-x-3 text-white opacity-65 items-center mt-6">
            <div className="flex justify-center items-center h-5 w-5">
              <IoMdMail />
            </div>
            <p className="text-sm">henry@sewalogistics.com.vn</p>
          </div>
          <div className="flex gap-x-3 text-white opacity-65 items-center mt-6">
            <div className="flex justify-center items-center h-5 w-5">
              <TbWorld />
            </div>
            <a href="http://www.sewalogistics.com.vn/" className="text-sm">www.sewalogistics.com.vn</a>
          </div>
          <div className="h-10" />
          <Title title={"OPENING TIMES"} />
          <div className="flex gap-x-3 text-white opacity-65 items-center mt-10">
            <div className="flex justify-center items-center h-5 w-5">
              <MdAccessTimeFilled />
            </div>
            <p className="text-sm">
              Open 8:00 AM to 5:00 PM (Monday to Friday)
            </p>
          </div>
        </div>
        <div>
          {" "}
          <Title title={"Gallery"} />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 mt-10 md:pr-10">
            {dataGallery.map(({ imageLink }, index) => (
              <div key={index}>
                <DialogWithImage image={imageLink} />
              </div>
            ))}
          </div>
        </div>
        <div>
          <Title title={"My Location"} />
          <iframe
            className="mt-10 rounded-md"
            title="Bản đồ"
            width="100%"
            height="275"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.1504925783897!2d106.70701457596218!3d10.799783589350358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529464a079a5b%3A0xa94853f639e9f172!2zMTk1LzU5IFjDtCBWaeG6v3QgTmdo4buHIFTEqW5oLCBQaMaw4budbmcgMTcsIELDrG5oIFRo4bqhbmgsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCA3MDAwMCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1710224527377!5m2!1svi!2s"
          ></iframe>
        </div>
      </div>
      <div className="bg-gray-900 h-14 md:px-44 md:flex justify-between items-center">
        <div className="flex md:block justify-center">
    
          <div className="flex items-center gap-x-6 text-lg ">
            <div className=" cursor-pointer">
              <FaGoogle className="text-white hover:text-blue-800 duration-200" />
            </div>
            <div className=" cursor-pointer">
              <FaFacebookF className="text-white hover:text-blue-800 duration-200" />
            </div>
            <div className=" cursor-pointer">
              <FaTwitter className="text-white hover:text-blue-800 duration-200" />
            </div>
            <div className=" cursor-pointer">
              <SiZalo className="text-white hover:text-blue-800 duration-200 text-3xl" />
            </div>
          </div>
        </div>

        <div className="flex md:block justify-center ">
          <p className="text-white text-base">
            @ 2017 SewaLogistics{" "}
            {/* <span className="font-medium opacity-90">Điền sau</span> */}
          </p>
        </div>
      </div>
    </div>
  );
}
