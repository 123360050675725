import React from "react";
import ItemCarousel from "./ItemCarousel";
import bg1 from "../../image/background/air1.jpg";

import bg3 from "../../image/background/cn3.webp";
import bg4 from "../../image/background/cn4.jpg";
import bg5 from "../../image/background/cnt3.jpg";
import { Carousel } from "@material-tailwind/react";
export default function CarouselHeader({ action1, action2 }) {
  return (
    <div className="absolute z-0 w-full">
      <Carousel
        className=" h-screen"
        autoplay={true}
        transition={{ duration: 2, type: "tween" }}
        loop={true}
        autoplayDelay={9000}
      >
        <ItemCarousel
          action1={(action1)}
          action2={action2}
          image={bg1}
          title={"Who we are"}
          content={
            "Sewa Logistics - your trusted partner in the logistics industry! With over 5 years of experience, we take pride in being the ideal support for every business looking to optimize their supply chain and transportation."
          }
        />
        <ItemCarousel
          action1={action1}
          action2={action2}
          image={bg3}
          title={"Quality Services"}
          content={
            "We commit to providing our customers with top-notch logistics services, ranging from efficient transportation of goods to comprehensive warehouse management and end-to-end supply chain solutions. With an experienced team of professionals, we are always ready to offer advice and implement the most effective solutions for your needs.."
          }
        />
        <ItemCarousel
          action1={action1}
          action2={action2}
          image={bg5}
          title={"Elevate Your Logistics Experience"}
          content={
            "Technology is the cornerstone of our operations! Our advanced tracking and cargo management system ensures you have a clear view of the location and status of your goods anytime, anywhere. Simultaneously, we guarantee absolute safety for all your shipments, prioritizing modern and secure components for our services."
          }
        />
        <ItemCarousel
          action1={action1}
          action2={action2}
          image={bg4}
          title={"Sustainability Commitment"}
          content={
            "We not only deliver economic value but also pledge to conduct all our operations with a sustainable vision. By integrating green solutions and reducing carbon footprint, we contribute to both the environment and the communities we serve. At Sewa Logistics, sustainability is our commitment to a better future."
          }
        />
      </Carousel>
    </div>
  );
}
