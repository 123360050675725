import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dataGallery from "../../data/dataGallery";
import { listDataLogo } from "../../data/dataLogo";
import { Carousel } from "@material-tailwind/react";
export default function AgencyTrade() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-10 h-full min-h-screen mt-10 md:mt-0">
      <div className="flex items-center px-3 md:px-36 justify-center">
        <div className="md:max-w-[600px] w-full">
          <p
            style={{
              fontFamily: "Truculenta",
            }}
            className="text-4xl text-gray-800  sans-serif font-bold text-center"
          >
            Agency Trade
          </p>
        
          <p className=" text-gray-700 text-lg  leading-relaxed mt-3 text-center">
            We care about the sustainability of the supply chains of our
            Customers and Partners Global
          </p>
          <div className="md:max-w-[700px] mt-10 w-full max-w-[350px]">
            <Slider {...settings}>
              {listDataLogo?.map((item, index) => (
                <div key={index} className="">
                  <img
                    alt="Sewa Logistics img"
                    className=" object-fill rounded-md h-40 w-40"
                    src={item?.linkImg}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="md:px-10 px-3 py-24 w-full  flex justify-between items-center">
        <Carousel
          className="rounded-xl max-h-[500px]"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {dataGallery?.map((item, index) => (
            <img
              src={item?.imageLink}
              alt="imagee 1"
              className="h-full w-full object-cover"
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}
