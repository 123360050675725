import React from "react";
import image23 from "../../image/background/cnt3.jpg";
import nen2 from "../../image/background/nen2.jpg";
import logoTran from "../../image/logo-trans.png";

export default function IntroComponent() {
  return (
    <div
      style={{ backgroundImage: `url(${nen2})` }}
      className={` bg-cover bg-center bg-no-repeat h-[350px] md:h-[700px] relative w-full`}
    >
      <div className="absolute inset-0 bg-white bg-opacity-50"></div>

      <div className="z-10 absolute grid grid-cols-1 md:grid-cols-2 w-full h-full">
        <div className="flex justify-center items-center h-full ">
          <h1
            style={{
              fontFamily: "Truculenta",
            }}
            className="md:text-5xl text-2xl text-green-900 sans-serif md:max-w-[700px] max-w-[350px] leading-relaxed text-center md:text-start md:mt-0 mt-24 mx-10 xl:mx-0"
          >
            Your experienced and global trading partner for Logistics.
          </h1>
        </div>
        <div className="md:h-[700px] h-[350px] mt-40 max-w-screen-lg relative">
          <div className=" absolute z-10 md:h-40 h-20  md:bottom-5 md:right-5 hidden md:block">
            <img
              className="w-full h-full max-h-20 object-cover"
              alt=""
              src={logoTran}
            />
          </div>

          <img
            alt="Sewa Logistics img"
            className="md:h-full h-64 w-fill object-cover brightness-50 hover:brightness-100 duration-700 "
            src={image23}
          />
        </div>
      </div>
    </div>
  );
}
