import lg1 from "../image/logo/FSC.jpeg";
import lg2 from "../image/logo/BRC.jpg";
import lg3 from "../image/logo/GACC.png";
import lg4 from "../image/logo/GLOBAL.jpg";
import lg5 from "../image/logo/HALA.jpg";
import lg6 from "../image/logo/IFS.jpg";

export const listDataLogo = [
  { linkImg: lg1 },
  { linkImg: lg2 },
  { linkImg: lg3 },
  { linkImg: lg4 },
  { linkImg: lg5 },
  { linkImg: lg6 },
];
