import React from "react";
import dataGallery from "../../data/dataGallery";
import { listServices } from "../../data/dataServices";

export default function Products() {
  return (
    <div className="md:px-20 px-5 w-full bg-gray-200 py-20 mt-5 md:mt-0">
      <div className="text-center w-full flex justify-center">
        <p
          style={{
            fontFamily: "Truculenta",
          }}
          className="md:text-4xl text-3xl text-red-800 max-w-[500px] sans-serif leading-relaxed text-center font-bold"
        >
          Sewa's services
        </p>
      </div>{" "}
      <div className="text-center w-full flex justify-center">
        <p
          style={{
            fontFamily: "Truculenta",
          }}
          className="md:text-4xl text-3xl text-gray-800 max-w-[500px] sans-serif leading-relaxed text-center"
        >
          We provide the following services
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-10 2xl:px-64 md:mx-auto mt-10">
        <div className="md:h-[500px] h-[230px] w-full relative  ">
          <div className="absolute inset-0 flex justify-center items-center">
            <div className=" px-4 py-3 rounded-lg bg-white text-black w-fit h-14 z-10 absolute opacity-90 font-medium flex justify-center items-center text-lg hover:bg-red-800 hover:text-white duration-200">
              {listServices[0].name}
            </div>
          </div>

          <img
            className="w-full h-full object-cover rounded-lg absolute z-0 brightness-75 hover:brightness-100 duration-700"
            alt="Sewa Logistics img"
            src={listServices[0].img}
          />
        </div>
        <div className="md:h-[500px] h-[230px] w-full relative">
          <div className="absolute inset-0 flex justify-center items-center">
            <div className=" px-4 py-3 rounded-lg bg-white text-black w-fit h-14 z-10  text-center absolute opacity-90 font-medium flex justify-center items-center text-lg hover:bg-red-800 hover:text-white duration-200">
              {listServices[1].name}
            </div>
          </div>

          <img
            className="w-full h-full object-cover rounded-lg absolute z-0 brightness-75 hover:brightness-100 duration-700"
            alt="Sewa Logistics img"
            src={listServices[1].img}
          />
        </div>
        <div className="w-full">
          <div className="h-[230px] w-full relative">
            <div className="absolute inset-0 flex justify-center items-center">
              <div className=" px-4 py-3 rounded-lg bg-white text-black w-fit h-14 z-10  text-center absolute opacity-90 font-medium flex justify-center items-center text-lg hover:bg-red-800 hover:text-white duration-200">
                {listServices[2].name}
              </div>
            </div>

            <img
              className="w-full h-full object-cover rounded-lg absolute z-0 brightness-75 hover:brightness-100 duration-700"
              alt="Sewa Logistics img"
              src={listServices[2].img}
            />
          </div>
          <div className="h-[230px] w-full relative mt-3 md:mt-10">
            <div className="absolute inset-0 flex justify-center items-center">
              <div className=" px-4 py-3 rounded-lg bg-white text-black w-fit h-14 z-10  text-center absolute opacity-90 font-medium flex justify-center items-center text-lg hover:bg-red-800 hover:text-white duration-200">
                {listServices[3].name}
              </div>
            </div>

            <img
              className="w-full h-full object-cover rounded-lg absolute z-0 brightness-75 hover:brightness-100 duration-700"
              alt="Sewa Logistics img"
              src={listServices[3].img}
            />
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-10  2xl:px-64 md:mx-auto mt-3 md:mt-10">
        <div className=" md:col-span-2">
          <div className="h-[230px] w-full relative  ">
            <div className="absolute inset-0 flex justify-center items-center">
              <div className=" px-4 py-3 rounded-lg bg-white text-black w-fit h-14 text-center z-10 absolute opacity-90 font-medium flex justify-center items-center text-lg hover:bg-red-800 hover:text-white duration-200">
                {listServices[4].name}
              </div>
            </div>

            <img
              className="w-full h-full object-cover rounded-lg absolute z-0 brightness-75 hover:brightness-100 duration-700"
              alt="Sewa Logistics img"
              src={listServices[4].img}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="h-[230px] w-full relative ">
            <div className="absolute inset-0 flex justify-center items-center w-full">
              <div className=" px-4 py-3 rounded-lg bg-white text-black w-fit min-h-14 z-10  text-center absolute opacity-90 font-medium flex justify-center items-center text-lg hover:bg-red-800 hover:text-white duration-200">
                {listServices[5].name}
              </div>
            </div>

            <img
              className="w-full h-full object-cover rounded-lg absolute z-0 brightness-75 hover:brightness-100 duration-700"
              alt="Sewa Logistics img"
              src={listServices[5].img}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
