import { Typography, Button } from "@material-tailwind/react";
import React from "react";

export default function ItemCarousel({
  image,
  title,
  content,
  action1,
  action2,
}) {
  return (
    <div className="relative h-full w-full">
      <img src={image} alt=" 1" className="h-full w-full object-cover" />
      <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/65">
        <div className="w-3/4 text-center md:w-2/4">
          <Typography
            variant="h1"
            color="white"
            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
          >
            {title}
          </Typography>
          <Typography variant="lead" color="white" className="mb-12 opacity-80">
            {content}
          </Typography>
          <div className="flex justify-center gap-2">
            <Button onClick={action1} size="lg" color="white">
              Learn More
            </Button>
            <Button onClick={action2} size="lg" color="white" variant="text">
              Contact US
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
