import React from "react";

export default function Contact() {
  return (
    <div className="py-3 md:py-16 px-3 md:px-40 mb-32">
      <p
        style={{
          fontFamily: "Truculenta",
        }}
        className="text-4xl text-gray-700 sans-serif "
      >
        Let's get in touch.
      </p>
      <p className="mt-5 text-xl text-gray-700 max-w-[600px]">
        Tell our team more about you and your trading requirements without
        obligation.
      </p>
      <p className="mt-10 text-xl font-medium text-gray-700">
        Tel: <span className=" font-normal">+84 929 298 128</span>
      </p>
      <p className="mt-5 text-xl font-medium text-gray-700">
        Email: <span className=" font-normal">henry@sewalogistics.com.vn</span>
      </p>
    </div>
  );
}
