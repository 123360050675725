import React from "react";
import battay from "../../image/background/battay.jpg";
export default function Teams() {
  return (
    <div className="px-3 xl:px-48 py-3 md:py-20">
      <p
        style={{
          fontFamily: "Truculenta",
        }}
        className="md:text-4xl text-2xl text-gray-800 max-w-[500px] sans-serif leading-relaxed"
      >
        Our team is globally connected.
      </p>
      <div className="relative group flex justify-center items-center mt-10">
        <img
          src={battay}
          alt="Hình ảnh Sewa Logistics img"
          className="transition duration-700 ease-in-out transform group-hover:brightness-75 rounded-lg w-full max-h-[500px] object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 duration-700 md:group-hover:opacity-100">
          <h1 className="text-white font-medium md:text-3xl text-lg px-24 text-center leading-relaxed ">
            Welcome to our Logistics Team at Sewa Logistics. With precision
            planning, cutting-edge technology, and a commitment to excellence,
            we ensure your goods reach their destination seamlessly. Explore
            logistics redefined with us.
          </h1>
        </div>
      </div>
    </div>
  );
}
