import airImg from "../image/services/Air-cargo.png";
import customerImg from "../image/services/customer-clearance.jpg";
import wareHouseImg from "../image/services/kho.avif";
import noidiaImg from "../image/services/noidia.jpg";
import oceanImg from "../image/services/ocean.png";
import oconusImg from "../image/services/OCONUS-NewSize-720x400.webp";
export const listServices = [
  {
    name: "OCEAN FREIGHT",
    img: oceanImg,
  },
  {
    name: "AIR FREIGHT",
    img: airImg,
  },

  {
    name: "CUSTOMS CLEARANCE",
    img: customerImg,
  },
  {
    name: "OVERSEAS",
    img: oconusImg,
  },
  {
    name: "DOMESTICS TRANSPORTATION",
    img: noidiaImg,
  },
  {
    name: (
      <div
        dangerouslySetInnerHTML={{
          __html: "WAREHOUSE <br/> & DISTRIBUTION PACKING",
        }}
      />
    ),
    img: wareHouseImg,
  },
];
